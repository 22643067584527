import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'

const ServiceDesignMuvisiPrivate = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Muvisi Private</title>
      <meta name="Service Design - Muvisi Private" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.muvisi_cover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">SERVICE DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">BRAND IDENTITY</span>
            <h1>muvisi.</h1>
            <h3>
              A mobility service that streamlines parking across the entire
              ecosystem of drivers, operators and municipality. For drivers it
              provides an end-to-end solution through reliable parking
              availability data; which makes it stress-free and convenient to
              find & navigate to on-street parking spots, track sessions and pay
              seamlessly for actual parked time – whilst assisting parking
              operators & cities to manage their assets more effectively.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>
                  with me for more details related to this project; such as my
                  role, the problems i faced, the processes i used and how i
                  addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products & services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Parking is one of the most difficult and dysfunctional parts
                  of Italian city life. Most car journeys in cities involve
                  parking pain on daily basis; from uncertainty (parking
                  availability, prices, restrictions) to inconvenience (full car
                  parks, slow payments, unfair fines).
                </p>
                <p>
                  We aimed to solve these parking problems by creating a fully
                  digitalized parking ecosystem that address all sides of the
                  market; drivers (B2C), private parking operators (B2B) and
                  city municipalities as a white-label (B2G).
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 5 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> An urban mobility service that
                  assists users to find parking availability..
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Benchmarking competitors, Blue-sky
                  research to gather inspiration from other industries,
                  preparing for on-field interviews, carrying out service
                  safaris, user-behaviour observation and interviews, research
                  analysis, brand strategy, experience mapping front & back
                  office, wireframing digital product, visual design
                  consultation and prototyping of digital touchpoints.
                </span>
                {/* <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <div className="span-6">
                      <Img
                        fluid={
                          props.data.interparking_logo.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div className="span-4">
                      <Img fluid={props.data.sis_logo.childImageSharp.fluid} />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.muvisichallenge.childImageSharp.fluid}
                alt=""
                title=""
              />
            </div>
            <p className="full-text">
              Scope: In phase 1 of the project, we considered only on-street
              parking scenario. The later phases were planned to include
              off-street parking and a multi-modal mobility service to be scaled
              to multiple cities. The challenge was thus to design phase 1 in a
              way that allowed for this evolution & expansion of the service.
            </p>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
          </section>
          <section className="private">
            <div className="inner">
              <div className="private-logo" />
              <h3>This project is private.</h3>
              <p className="private-text">
                get in touch with me on <u>sagar.satam25@gmail.com</u> <br />
                to discuss about this project and get access to view the entire
                project.
              </p>
            </div>
          </section>
          {/* <section id="footertopcta" className="inner">
            <p className="text arrow-front">
              check out my <Link to="/#one">featured work.</Link>
            </p>
          </section> */}
          <section id="footertopcta" className="inner">
            <p className="text arrow-front">
            <a href="/#one">check out my featured work.</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-tecne">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">tecne.</div>
                <div className="text">
                  Next gen combat training with an underglove fitted with
                  sensors & an app that gives precise feedback to improve.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>INTERACTION DESIGN</li>
                    <li>DIGITAL UX/UI</li>
                    <li>PRODUCT DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-2">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 2).</div>
                <div className="text">
                  A mobile application to find & book food experiences; and a
                  backend dashboard for management of listings.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignMuvisiPrivate

export const fluidMuvisiPrivateImage = graphql`
  fragment fluidMuvisiPrivateImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    muvisi_cover: file(
      relativePath: {
        eq: "projects/service/muvisi/cover/muvisi-cover-image-blurred.jpg"
      }
    ) {
      ...fluidMuvisiPrivateImage
    }
    interparking_logo: file(
      relativePath: { eq: "projects/service/muvisi/interparking-logo.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    sis_logo: file(
      relativePath: { eq: "projects/service/muvisi/sis-logo.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisichallenge: file(
      relativePath: { eq: "projects/service/muvisi/challenge/1.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess2: file(
      relativePath: { eq: "projects/service/muvisi/process/2.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess3: file(
      relativePath: { eq: "projects/service/muvisi/process/3.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess4: file(
      relativePath: { eq: "projects/service/muvisi/process/4.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess5: file(
      relativePath: { eq: "projects/service/muvisi/process/5.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess6: file(
      relativePath: { eq: "projects/service/muvisi/process/6.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess7: file(
      relativePath: { eq: "projects/service/muvisi/process/7.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess8: file(
      relativePath: { eq: "projects/service/muvisi/process/8.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess9: file(
      relativePath: { eq: "projects/service/muvisi/process/9.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess10: file(
      relativePath: { eq: "projects/service/muvisi/process/10.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess11: file(
      relativePath: { eq: "projects/service/muvisi/process/11.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess12: file(
      relativePath: { eq: "projects/service/muvisi/process/12.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess13: file(
      relativePath: { eq: "projects/service/muvisi/process/13.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess14: file(
      relativePath: { eq: "projects/service/muvisi/process/14.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess15: file(
      relativePath: { eq: "projects/service/muvisi/process/15.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess16: file(
      relativePath: { eq: "projects/service/muvisi/process/16.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess17: file(
      relativePath: { eq: "projects/service/muvisi/process/17.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess18: file(
      relativePath: { eq: "projects/service/muvisi/process/18.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess19: file(
      relativePath: { eq: "projects/service/muvisi/process/19.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess20: file(
      relativePath: { eq: "projects/service/muvisi/process/20.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess21: file(
      relativePath: { eq: "projects/service/muvisi/process/21.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess22: file(
      relativePath: { eq: "projects/service/muvisi/process/22.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess23: file(
      relativePath: { eq: "projects/service/muvisi/process/23.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess24: file(
      relativePath: { eq: "projects/service/muvisi/process/24.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess25: file(
      relativePath: { eq: "projects/service/muvisi/process/25.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess26: file(
      relativePath: { eq: "projects/service/muvisi/process/26.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess27: file(
      relativePath: { eq: "projects/service/muvisi/process/27.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess28: file(
      relativePath: { eq: "projects/service/muvisi/process/28.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess29: file(
      relativePath: { eq: "projects/service/muvisi/process/29.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess30: file(
      relativePath: { eq: "projects/service/muvisi/process/30.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess31: file(
      relativePath: { eq: "projects/service/muvisi/process/31.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess32: file(
      relativePath: { eq: "projects/service/muvisi/process/32.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess33: file(
      relativePath: { eq: "projects/service/muvisi/process/33.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess34: file(
      relativePath: { eq: "projects/service/muvisi/process/34.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess35: file(
      relativePath: { eq: "projects/service/muvisi/process/35.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess36: file(
      relativePath: { eq: "projects/service/muvisi/process/36.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess37: file(
      relativePath: { eq: "projects/service/muvisi/process/37.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess38: file(
      relativePath: { eq: "projects/service/muvisi/process/38.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisiprocess39: file(
      relativePath: { eq: "projects/service/muvisi/process/39.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisioutcome40: file(
      relativePath: { eq: "projects/service/muvisi/outcome/40.jpg" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    muvisi_process: file(
      relativePath: { eq: "projects/process/muvisi-process.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidMuvisiPrivateImage
    }
    project1: file(relativePath: { eq: "projects/15.png" }) {
      ...fluidMuvisiPrivateImage
    }
    project2: file(relativePath: { eq: "projects/4.png" }) {
      ...fluidMuvisiPrivateImage
    }
  }
`
